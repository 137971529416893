<template>
    <div class="EmployeesJoin">
        <div>
            <div>
                <div style="font-weight: 400; color: #606060; font-size: 12px; height: 40px; line-height: 40px; padding: 0 21px;">商家信息</div>
                <div style="font-weight: 500; background: #fff;  padding: 0 21px; height: 42px; color: #000; font-size: 14px; display: flex; justify-content: space-between; align-items: center;">
                    <div style="">申请商家名称</div>
                    <div>{{activityDetails.admin_real_name}}</div>
                </div>
            </div>

            <div style="margin-top: 15px;">
                <div style="font-weight: 400; color: #606060; font-size: 12px; height: 40px; line-height: 40px; padding: 0 21px;">请填写一下信息</div>
                <el-input v-model="userName" placeholder="请输入您的姓名"></el-input>
                <el-input v-model="userPhone" placeholder="请输入您的手机号" style="margin-top: 2px;"></el-input>
            </div>
            
            <div style="position: fixed; bottom: 30px; left: 0; width: 100vw;">
                <div @click="handleFormSubmit" style="color: #fff; background: #4372FC; border-radius: 5px; margin: 0 10px 0 14px; height: 43px; line-height: 43px; text-align: center;">
                    提交申请
                </div>
            </div>
        </div>

        <!-- 提示 -->
        <div v-if="isShowHint">
            <div style="color: #fff; font-weight: 400; font-size: 12px; position: fixed; top: 50%; left: 50%; transform: translate(-50%, -50%); background: rgba(0, 0, 0, .6); border-radius: 4px; padding: 10px 15px;">
                {{hintContent}}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "EmployeesJoin",
    components: {

    },
	data() {
		return {
			// 姓名
            userName: '',
            // 手机号
            userPhone: '',
            // 活动id
            id: '',
            // apply_code
            apply_code: '',
            // 是否显示提示
            isShowHint: false,
            // 提示内容
            hintContent: '',
            // 活动详情
            activityDetails: {},
		} 
	},
    mounted() {
        const { apply_code, id } = this.$route.query;
        this.apply_code = apply_code;
        this.id = parseInt(id);
        
        this.getCurrentRoleState();
    },
    methods: {
        /** 获取当前是否已经角色的状态 */
        getCurrentRoleState() {
            this.$axios.post("/user/checkQrcode", {
                apply_code: this.apply_code,
            }).then(res => {
                localStorage.setItem("userInfo", JSON.stringify({...JSON.parse(localStorage.getItem("userInfo")), admin_group_id: res.admin_group_id}));
                if(res.jump_apply) {
                    this.getActivityDetails(this.id);
                }
                else {
                    this.$router.push("/");
                }
            }).catch(error => {
                this.showMessage(error);
            });
        },

        /** 表单提交 */
        handleFormSubmit() {
            if(this.clearStringSpace(this.userName) === "" || this.clearStringSpace(this.userPhone) === "") {
                this.showMessage("请填写信息");
                return;
            }

            // 发送请求...
            this.$axios.post("/user/applyStaff", {
                apply_code: this.apply_code,
                realname: this.userName,
                telphone: this.userPhone,
            }).then(res => {
                this.showMessage(res.msg);
            }).catch(error => {
                this.showMessage(error);
            });
        },

        /** 获取活动详情 */
        getActivityDetails(id) {
            this.$axios.post("/events/getInfo", {id}).then(res => {
                this.activityDetails = res;
            }).catch(error => {
                this.showMessage(error);
            });
        },

        /** 显示提示 */
        showMessage(message) {
            this.hintContent = message;
            this.isShowHint = true;
            const timeout = setTimeout(() => {
                this.isShowHint = false;
                clearTimeout(timeout);
            }, 1000);
        },

        /** 去掉字符串左右空格 */
        clearStringSpace(string) {
            string = string ? string : "";
            return string.trimStart().trimEnd();
        },
    },
};
</script>

<style scoped lang="scss">
    .EmployeesJoin {
        background: #f5f5f5;
        min-height: 100vh;
        height: 100%;
    }
</style>
